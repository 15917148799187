<template>
    <div style="background: #404040">
        <div>
            <headerComponent></headerComponent>
        </div>
        <div class="cookies">
            <h2>Monoverse - Cookies Policy</h2><br><br>
            <h3>1. About us and this Cookie Policy</h3>
            <h3><br />1.1. About Us</h3>
            <h4><br />Monoverse.com is owned by Monoverse Capital Ltd, a UK Company with registered
                office at Level 1, 124 Cleveland Street, London, W1T 6PH, and maintained by Monoverse
                Platform Inc. with registered office at 651 N Broad Street, Suite 201, Middletown
                Delaware, 19709, collectively referred as &ldquo;Monoverse. 1.2. Purpose of this
                Cookies Policy Monoverse uses cookies and other similar technologies (hereafter
                referred together as &ldquo;Cookies&rdquo;) to distinguish from other users of
                the Website. Monoverse takes care to respect your privacy and its legal obligations
                regarding the use of Cookies and the protection of your personal data. The aim of this
                Cookies Policy is therefore to provide you with clear and comprehensive information
                about the Cookies Monoverse uses through the Website and the purposes of using them.
                This Cookies Policy may be updated from time to time, so please check back our Cookies
                Policy on a regular basis for any updates.</h4>
            <h4>2. What are Cookies, web beacons and similar technologies These technologies are data files
                placed on your devices that allow us to record information when you visit or interact with
                our Website or Service. When you visit our Website, we may record some information in the
                form of Cookies (this may include information like your IP address and other technical information).</h4>
            <br><h3>2.1. Cookies</h3>
            <h4><br />A cookie is a small sized text file (often encrypted)
                that can be stored in the memory of your browser or on your computer, mobile phone or
                tablet when you visit a website. Cookies record information about your use of the website,
                such as the language in which you visit the website. Thanks to the cookies, it will then be
                possible to recognize auser and their preferences when they returns to the website.</h4>
            <h4>&nbsp;</h4>
            <br><h3>2.2. Web Beacons</h3>
            <h4><br />A web beacon is made up of a clear file, usually a 1x1 pixel, which is usually transparent, that is loaded on our web pages to collect anonymous traffic metrics related to your visit, your web browser/device, browsing activity, or onsite behavior. They are usually used in conjunction with cookies to understand your behavior on our Website and the way you interact with our content, but also to analyze our Website performance.&nbsp;</h4>
            <br><h3>2.3. Scripts</h3>
            <h4><br />A script is a small piece of website code placed on our Website to provide you with interactive experiences. They are also used to collect data that we use for website&nbsp; analytics, or to provide data on the effectiveness of our advertising.</h4>
            <br><h3>2.4. Similar technologies</h3>
            <h4>Technologies that store information in your browser or device utilizing local shared objects or local storage, such as flash cookies, HTML 5 cookies, and other web application software methods. These technologies can operate across all your browsers.</h4>
            <br /><h3>3. What technologies do we use and why</h3><br/>
            <h4>Monoverse is using the technologies provided by Cloudflare and Google Analytics to handle Cookies, web beacons and other similar technologies, for:</h4>
            <h4>- The good functioning of our Website and Service</h4>
            <h4>- The improvement of our Server Performance<br />- The development of targeted advertisements<br />We are using the following categories on our Website:</h4>
            <h4>&nbsp;</h4>
            <h4>&nbsp;</h4><br>
            <h3>3.1. Strictly necessary // USED</h3>
            <h4><br />These Cookies are necessary for the proper functioning and performance of the&nbsp; Website. They cannot be disabled via our cookies banner. You may disable these Cookies by changing your browser settings, but this may affect some parts of the Website functions. Strictly necessary Cookies are used by Cloudflare for delivering content to web users. For more information on how Cloudflare uses the data collected for their service, see here:<a href="https://www.cloudflare.com/cookie-policy/"> https://www.cloudflare.com/cookie-policy/</a></h4>
            <br /><h3>3.2. Functional // NOT USED</h3>
            <h4>These Cookies are used to improve and personalize the functionality of the Website. They may be set by Monoverse, or by third parties whose services are used on our Website. Please note that Monoverse is not using any functional cookies, since users cannot interact with our content. Functional Cookies may be used by Stripe during the Checkout Process.</h4>
            <h3><br />3.3. Analytics // USED</h3>
            <h4>Monoverse use Google Analytics to carry out statistical analysis of page use, page interactions and paths through the Website to evaluate and develop our Website. This is known as &amp;#39;digital analytics&amp;#39;. We may also record certain information that customers provide during a Website purchase or other process. This information allows us to understand individual behaviours and needs more accurately. For more information <br>on how Google uses the data collected via this service, see here: <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a></h4>
            <h4>To opt out of being tracked via Google Analytics, you can also use Google&amp;#39;s opt-out<br />browser add-on: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>&nbsp;</h4>
            <h4>&nbsp;</h4><br>
            <h3>3.4. Marketing // NOT USED<br /><br>Marketing technology may include first or third-party Cookies. They may be used by those companies to profile your interests and propose you relevant advertisements on other websites. Monoverse is NOT using any marketing technology or cookies, whatsoever.</h3>
            <br><br>
            <h3>4. How to manage your cookie preferences?</h3>
            <h3><br />4.1. Cookie management via our cookie banner</h3>
            <h4><br />The first time you visit our website, you will be asked to set your preferences for the use of Cookies. Cookies that are not strictly necessary are turned off by default, unless and until you allow all or some of them through our cookie banner. At any time, you can manage your preferences regarding the use of Cookies via our cookies banner. You can therefore (i) select the category(ies) of Cookies you allow us to use, (ii) accept the use of all Cookies or (iii) reject all Cookies (except necessary Cookies which cannot be switched off through our cookie banner).</h4>
            <h4>&nbsp;</h4><br>
            <h3>4.2. Cookie management via our browser&nbsp;</h3>
            <h4><br />If you wish to know which Cookies are installed on your device or if you wish to delete them, you may use a dedicated setting on your web browser. You can also change your<br />Cookie settings manually by configuring your web browser. Further instructions about the way to proceed are available via the links specified below:</h4>
            <h4><br />- Cookie settings in Internet Explorer<br />- Cookie Settings for Microsoft Edge<br />- Cookie settings in Firefox<br />- Cookie settings in Chrome<br />- Cookie settings in Safari</h4><br>
            <h3>5. Contact Us</h3><h4><br />If you have any questions about this Cookies Policy, you can contact us by visiting this page on our website: http://www.monoverse.com/contact</h4>
        </div>
        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import nextStepsG from "@/components/reusable/nextSteps";

    export default {
        name: "thankYouMail",
        components: {headerComponent,mainfooter,DomainsGrid,nextStepsG},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


